/* TestimonialCarousel.css */

/* Root container */
.testimonial-carousel {
  max-width: 100%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: rgb(149, 198, 199); /* Light teal background */
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: rgb(27, 162, 181); /* Vibrant turquoise text */
}

/* Title styling */
.testimonial-carousel h2 {
  font-size: 2rem;
  color: rgb(85, 148, 152); /* Medium teal for title */
  margin-bottom: 1.5rem;
}

/* Carousel wrapper with arrows */
.carousel-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

/* Carousel container for items */
.carousel-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 1rem;
  padding-bottom: 1rem;
  scroll-behavior: smooth;
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

/* Individual plate item */
.carousel-item {
  flex: 0 0 90%;
  max-width: 250px;
  padding: 1rem;
  background-color: rgb(126, 183, 185); /* Soft cyan background for items */
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  scroll-snap-align: center;
  transition: transform 0.3s ease;
}

.carousel-item:hover {
  transform: scale(1.05);
}

/* Plate photo styling */
.plate-photo {
  width: 100%;
  height: auto;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  object-fit: cover;
}

/* Plate name styling */
.plate-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: rgb(53, 127, 135); /* Muted teal */
  margin-bottom: 0.5rem;
}

/* Plate description styling */
.plate-description {
  font-size: 1rem;
  color: rgb(85, 148, 152); /* Medium teal */
}

/* Carousel navigation buttons */
.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(27, 162, 181, 0.8); /* Semi-transparent vibrant turquoise */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
  display: none; /* Hidden on mobile */
}

.carousel-button.left {
  left: -20px;
}

.carousel-button.right {
  right: -20px;
}

.carousel-button:hover {
  background-color: rgb(27, 162, 181);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .testimonial-carousel {
    padding: 1.5rem;
  }

  .testimonial-carousel h2 {
    font-size: 1.8rem;
  }

  .carousel-item {
    flex: 0 0 80%;
    max-width: 80%;
  }

  /* Hide arrows on smaller screens */
  .carousel-button {
    display: none;
  }
}

@media (min-width: 769px) {
  /* Show arrows on desktop screens */
  .carousel-button {
    display: block;
  }
}

@media (max-width: 480px) {
  .testimonial-carousel h2 {
    font-size: 1.6rem;
  }

  .carousel-item {
    flex: 0 0 90%;
    max-width: 90%;
  }
}
