/* PaymentContent.css */

/* Root container */
.payment {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: rgb(149, 198, 199);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    color: rgb(27, 162, 181);
    font-family: Arial, sans-serif;
  }
  
  /* Title styling */
  .payment h2 {
    font-size: 2rem;
    text-align: center;
    color: rgb(85, 148, 152);
    margin-bottom: 1.5rem;
  }
  
  /* Summary and form sections */
  .payment .order-summary,
  .payment .delivery-address-form,
  .payment .payment-method-selector {
    margin-bottom: 1.5rem;
  }
  
  /* Payment method selection */
  .payment-method-selector {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  /* Button styling */
  .payment-button {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    background-color: rgb(27, 162, 181);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .payment-button:hover {
    background-color: rgb(108, 166, 169);
  }
  
  /* Stripe card input field styling */
  .stripe-card-input {
    padding: 1rem;
    border: 1px solid rgb(126, 183, 185);
    border-radius: 6px;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    color: rgb(85, 148, 152);
    background-color: rgb(149, 198, 199);
  }
  
  /* Error and alert messages */
  .payment-alert {
    color: rgb(180, 50, 50);
    text-align: center;
    font-weight: bold;
    margin-top: 1rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .payment {
      padding: 1.5rem;
    }
  
    .payment h2 {
      font-size: 1.8rem;
    }
  
    .payment-button {
      font-size: 1.1rem;
    }
  
    .stripe-card-input {
      font-size: 0.9rem;
    }
  }
  