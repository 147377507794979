.basket {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 0 auto;
    color: #333;
    text-align: center;
}
.basket-icon {
    position: relative;
    cursor: pointer;
    font-size: 1.5rem;
  }
  
.basket h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #0077b5; /* Accent color for the title */
}

/* Basket Items */
.basket-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.basket-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.item-name {
    flex: 1;
    text-align: left;
    font-weight: 500;
    font-size: 1rem;
}

.item-price {
    font-size: 1rem;
    margin-right: 20px;
    color: #555;
}

/* Quantity Controls */
.item-quantity {
    display: flex;
    align-items: center;
    gap: 8px;
}

.item-quantity button {
    background-color: #0077b5;
    border: none;
    color: #fff;
    padding: 5px 10px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.item-quantity button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.item-quantity button:hover:not(:disabled) {
    background-color: #005fa3;
}

.item-quantity span {
    font-size: 1rem;
    width: 20px;
    text-align: center;
}

/* Empty Basket Message */
.basket-empty {
    font-size: 1rem;
    color: #777;
    margin: 20px 0;
}

/* Total Price */
.basket-total {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: 600;
    color: #0077b5;
}

/* Checkout Button */
.checkout-button {
    background-color: #0077b5;
    border: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
}

.checkout-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.checkout-button:hover:not(:disabled) {
    background-color: #005fa3;
}

/* Responsive Styling */
@media (max-width: 600px) {
    .basket {
        padding: 15px;
    }

    .basket h2 {
        font-size: 1.3rem;
    }

    .basket-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .item-price,
    .basket-total {
        font-size: 1rem;
    }

    .checkout-button {
        font-size: 1rem;
    }
}


/* Button Styles */
.basket-button {
    background-color: #0077b5; /* Primary color for 'Go to Payment' */
    color: #fff;
    padding: 10px 20px;
    font-size: 1.1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    margin-top: 10px;
}

.basket-button:hover {
    background-color: #005fa3; /* Darker shade on hover */
}

/* Clear Basket Button */
.clear-basket-button {
    background-color: #FF6B6B; /* Distinct red color for 'Clear Basket' */
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    margin-bottom: 10px;
}

.clear-basket-button:hover {
    background-color: #D9534F; /* Darker red shade on hover */
}

/* Disabled State for Both Buttons */
.basket-button:disabled,
.clear-basket-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .basket-button,
    .clear-basket-button {
        font-size: 1rem;
        padding: 8px 15px;
    }
}

/* Basket Icon Styles */
.basket-icon {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #355D5D; /* Muted teal for icon color */
  }
  
  /* Dropdown Menu Styles */
  .basket-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: 280px;
    background-color: #A5D3D3; /* Light teal for dropdown background */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 16px;
    margin-top: 100%;
    z-index: 200;
    display: flex;
    flex-direction: column;
    gap: 12px;

    
  }
  
  /* Button for proceeding to payment */
  .go-to-payment-button {
    padding: 10px 16px;
    background-color: #1DA9BB; /* Vibrant turquoise for the button background */
    color: #fff; /* White text for contrast */
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .go-to-payment-button:hover {
    background-color: #198B9B; /* Darken vibrant turquoise on hover */
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    /* Adjust dropdown width for smaller screens */
    .basket-dropdown {
      right: auto;
      left: -250px;
    }
  }
  

  /* Close button styling for basket dropdown */
.close-basket-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #355D5D; /* Muted teal */
  transition: color 0.3s ease;
}

.close-basket-button:hover {
  color: #1DA9BB; /* Vibrant turquoise on hover */
}