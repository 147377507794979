/* ProfileDetails.css */

/* Root container for profile details */
.profile-details {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: rgb(149, 198, 199); /* Light teal background */
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    color: rgb(27, 162, 181); /* Vibrant turquoise text */
    text-align: left;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  /* Individual field styling */
  .profile-details p {
    margin-bottom: 1rem;
    color: rgb(53, 127, 135); /* Muted teal for text */
  }
  
  .profile-details p strong {
    color: rgb(85, 148, 152); /* Medium teal for labels */
    font-weight: bold;
  }
  
  /* Edit button styling */
  .edit-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: rgb(27, 162, 181); /* Vibrant turquoise background */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1.5rem;
  }
  
  .edit-button:hover {
    background-color: rgb(85, 148, 152); /* Darker teal on hover */
  }
  
  .edit-button:focus {
    outline: 2px solid rgb(85, 148, 152); /* Focus outline for accessibility */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .profile-details {
      padding: 1.5rem;
    }
  
    .edit-button {
      padding: 0.65rem 1.25rem;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .profile-details {
      padding: 1rem;
      font-size: 0.9rem;
    }
  
    .edit-button {
      width: 100%;
      padding: 0.75rem;
      font-size: 0.9rem;
      text-align: center;
    }
  }
  