/* RecoverPasswordButton.css */

/* Button styling */
.recover-password-button {
    font-size: 0.9rem;
    color: rgb(27, 162, 181); /* Vibrant turquoise */
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.3s ease;
    margin-top: 1rem;
  }
  
  /* Hover effect */
  .recover-password-button:hover {
    color: rgb(85, 148, 152); /* Darker teal on hover */
  }
  
  /* Focus outline for accessibility */
  .recover-password-button:focus {
    outline: 2px solid rgb(85, 148, 152);
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .recover-password-button {
      font-size: 0.85rem;
      margin-top: 0.75rem;
    }
  }
  