/* PolicyStyles.css */

/* Root container for policy pages */
.policy-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: #333;
  line-height: 1.6;
  font-size: 1rem;
}

/* Back button styling */
.back-button {
  display: inline-block;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: #fff;
  background-color: #0077cc; /* Vibrant blue for the back button */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #005fa3; /* Darker blue on hover */
}

/* Headings styling */
.policy-page h1 {
  font-size: 2rem;
  color: #444;
  margin-bottom: 1.5rem;
}

.policy-page h2 {
  font-size: 1.5rem;
  color: #555;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

/* Paragraph styling */
.policy-page p {
  margin-bottom: 1.5rem;
  color: #666;
}

/* List styling */
.policy-page ul {
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.policy-page ul li {
  margin-bottom: 0.75rem;
  color: #444;
}

/* Address block styling */
.policy-page address {
  font-style: normal;
  margin-top: 1rem;
  color: #555;
  line-height: 1.5;
}

.policy-page a {
  color: #0077cc;
  text-decoration: none;
}

.policy-page a:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .policy-page {
    padding: 1.5rem;
  }

  .policy-page h1 {
    font-size: 1.8rem;
  }

  .policy-page h2 {
    font-size: 1.3rem;
  }

  .policy-page p, .policy-page ul li {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .policy-page {
    padding: 1rem;
  }

  .policy-page h1 {
    font-size: 1.5rem;
  }

  .policy-page h2 {
    font-size: 1.2rem;
  }

  .policy-page p, .policy-page ul li {
    font-size: 0.85rem;
  }
}
