/* RecoverPasswordForm.css */

/* Root form container */
.auth-form {
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: rgb(149, 198, 199); /* Light teal background */
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Title styling */
  .auth-form h2 {
    font-size: 1.8rem;
    color: rgb(27, 162, 181); /* Vibrant turquoise */
    margin-bottom: 1.5rem;
  }
  
  /* Information message styling */
  .info-message {
    color: rgb(53, 127, 135); /* Muted teal color for messages */
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
  
  /* Form group container */
  .form-group {
    margin-bottom: 1.5rem;
    text-align: left;
  }
  
  /* Label styling */
  .form-group label {
    display: block;
    font-size: 0.9rem;
    color: rgb(85, 148, 152); /* Medium teal */
    margin-bottom: 0.5rem;
  }
  
  /* Input field styling */
  .form-group input {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    color: #333;
    background-color: #fff;
    border: 1px solid rgb(126, 183, 185); /* Soft cyan */
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .form-group input:focus {
    border-color: rgb(27, 162, 181); /* Focus color */
  }
  
  /* Submit button styling */
  .auth-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: rgb(27, 162, 181); /* Vibrant turquoise */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-button:hover {
    background-color: rgb(85, 148, 152); /* Darker teal on hover */
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .auth-form {
      padding: 1.5rem;
    }
  
    .auth-form h2 {
      font-size: 1.6rem;
    }
  
    .auth-button {
      padding: 0.6rem;
      font-size: 0.9rem;
    }
  }
  