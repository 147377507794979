/* InfoSection.css */

/* Root container */
.info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    margin: 1.5rem 0;
    background-color: rgb(149, 198, 199);
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    color: rgb(27, 162, 181);
    text-align: center;
  }
  
  /* Icon styling */
  .info-icon {
    font-size: 3rem;
    color: rgb(27, 162, 181);
    margin-bottom: 1rem;
  }
  
  /* Title styling */
  .info-section h2 {
    font-size: 1.8rem;
    color: rgb(85, 148, 152);
    margin-bottom: 0.5rem;
  }
  
  /* Description styling */
  .info-section p {
    font-size: 1rem;
    color: rgb(53, 127, 135);
    line-height: 1.6;
    max-width: 700px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .info-icon {
      font-size: 2.5rem;
    }
  
    .info-section h2 {
      font-size: 1.6rem;
    }
  
    .info-section p {
      font-size: 0.95rem;
    }
  }
  