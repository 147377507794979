/* ProfileForm.css */

/* Root container for profile form */
.profile-form {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: rgb(149, 198, 199); /* Light teal background */
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    color: rgb(27, 162, 181); /* Vibrant turquoise text */
    font-size: 1rem;
  }
  
  /* Error message styling */
  .form-error {
    color: #e74c3c; /* Red color for error */
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
  
  /* Label styling */
  .profile-form label {
    display: block;
    font-size: 1rem;
    color: rgb(53, 127, 135); /* Muted teal */
    margin-bottom: 1rem;
  }
  
  /* Input field styling */
  .profile-form input {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    color: #333;
    background-color: #fff;
    border: 1px solid rgb(126, 183, 185); /* Soft cyan */
    border-radius: 4px;
    margin-top: 0.25rem;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .profile-form input:focus {
    border-color: rgb(27, 162, 181); /* Focus color */
  }
  
  /* Button group container */
  .button-group {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  /* Save button styling */
  .save-button {
    flex: 1;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: rgb(27, 162, 181); /* Vibrant turquoise */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .save-button:hover {
    background-color: rgb(85, 148, 152); /* Darker teal on hover */
  }
  
  /* Cancel button styling */
  .cancel-button {
    flex: 1;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    color: rgb(27, 162, 181); /* Vibrant turquoise text */
    background-color: transparent;
    border: 1px solid rgb(27, 162, 181); /* Border matching button color */
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .cancel-button:hover {
    background-color: rgb(27, 162, 181);
    color: #fff;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .profile-form {
      padding: 1.5rem;
    }
  
    .profile-form label {
      font-size: 0.9rem;
    }
  
    .button-group {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .profile-form {
      padding: 1rem;
    }
  
    .save-button, .cancel-button {
      font-size: 0.9rem;
      padding: 0.65rem;
    }
  }
  