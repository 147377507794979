/* OrderStatusBar.css */

/* Root container */
.order-status-bar {
    margin-top: 1rem;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    flex-direction: column; /* Stack progress bar and status word */
    color: rgb(85, 148, 152);
    font-family: Arial, sans-serif;
  }
  
  /* Status bar container */
  .status-bar {
    position: relative;
    width: 100%;
    height: 1.4rem;
    background-color: rgb(126, 183, 185);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 0.5rem;
  }
  
  /* Progress indicator */
  .progress {
    height: 100%;
    background-color: rgb(27, 162, 181);
    transition: width 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  /* Status label */
  .status-label {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }
  
  /* Status word */
  .status-word {
    font-size: 1rem;
    color: rgb(27, 162, 181);
    font-weight: bold;
    text-align: center;
  }
  
  /* Refunded status bar */
  .status-bar.refunded {
    background-color: rgb(85, 148, 152);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .status-bar.refunded span {
    font-size: 1rem;
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .status-bar {
      height: 1.2rem;
    }
  
    .status-label {
      font-size: 0.85rem;
    }
  
    .status-word {
      font-size: 0.9rem;
    }
  
    .order-status-bar {
      padding: 0.3rem 0;
    }
  }
  